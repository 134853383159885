import React from 'react';

const IconGooglePlay = ({width = 18, height = 18, color = "none"}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 22" fill={color} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M0.270621 2.08342C0.271008 2.1063 0.271885 2.12921 0.273259 2.15214V19.8481C0.271728 19.8737 0.270813 19.8992 0.270508 19.9247L9.1948 11.0004L0.270621 2.08342ZM0.641307 20.9682L0.69726 21.0241L0.702595 21.0188C0.920617 21.1881 1.18738 21.2842 1.46465 21.2922C1.76848 21.301 2.06583 21.2033 2.3053 21.0162L12.9953 14.9431L9.84238 11.7671L0.641307 20.9682ZM13.9914 14.3762L17.8893 12.1602C18.3093 11.9202 18.5718 11.6239 18.6768 11.314C18.7764 11.0199 18.7342 10.7134 18.55 10.4311C18.4108 10.2178 18.1906 10.0182 17.8893 9.84817L13.9817 7.62775L13.9613 7.61617L13.8893 7.57617L10.6093 10.8881L10.5393 11.0002L10.6093 11.1202L13.8893 14.4242L13.9773 14.3842L13.9913 14.3762H13.9914ZM12.9928 7.06027L9.84614 10.2376L0.637639 1.0365C0.637644 1.03649 0.637649 1.03649 0.637654 1.03648L0.633308 1.03214C0.649245 1.01813 0.665488 1.00454 0.682021 0.991374L0.69726 0.976135L0.699117 0.977993C0.916301 0.810897 1.1813 0.716005 1.45665 0.708062C1.76048 0.699298 2.05783 0.796947 2.29731 0.984136L12.9928 7.06027Z"
                  fill="#58B3D3"/>
        </svg>

    );
};

export default IconGooglePlay;
