import React, {Fragment, useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    loadOrganizationTemplatesAsync,
    loadTemplatesAsync,
    selectOrganizationTemplates,
    selectTemplates,
    updateOrganizationTemplatesAsync
} from "../../../store/reducers/excel/excelReducer";
import {selectLang} from "../../../store/reducers/main/main";
import {Translate} from "../../../lang/lang";
import {Controller, FormProvider, useForm} from "react-hook-form";
import {GetTemplateTypes} from "../../../enum/excel/ExcelTemplateTypes";
import toast from "react-hot-toast";
import {Col, Form, Row, Spinner} from 'react-bootstrap';
import {Button} from 'react-bootstrap';
import SelectTemplateComponent from "./SelectTemplateComponent";

const templateTypesFieldName = 'templateTypes';
const OrganizationTemplates = () => {

    const [loading, setLoading] = useState(false);
    const organizationTemplates = useSelector(selectOrganizationTemplates);
    const allTemplates = useSelector(selectTemplates);
    const lang = useSelector(selectLang);
    const dispatch = useDispatch();
    const t = Translate;

    const methods = useForm({
        defaultValues: {templateTypes: []}
    });
    console.log("allTemplates", allTemplates)
    const templateTypes = methods.watch(templateTypesFieldName);

    useEffect(() => {
        loadTemplates();
    }, [])

    useEffect(() => {
        if (organizationTemplates.length > 0 && allTemplates.length > 0) {
            console.log("working update template")
            updateTemplateTypes();
        }
    }, [organizationTemplates, allTemplates])
    const loadTemplates = () => {
        dispatch(loadTemplatesAsync());
    };

    const loadOrganizationTemplates = () => {
        dispatch(loadOrganizationTemplatesAsync());
    };

    const updateTemplateTypes = () => {
        const types = [];

        for (let i = 0; i < GetTemplateTypes.length; i++) {
            const templateType = GetTemplateTypes[i];

            const values = organizationTemplates.filter(template => template.type === templateType.id);
            const options = allTemplates.filter(template => template.type === templateType.id);

            types.push({
                values: values,
                options: options,
                label: templateType.name,
            })
            console.log(types)
        }

        // @ts-ignore
        methods.setValue(templateTypesFieldName, types);
    }

    const onSubmit = (formData) => {
        setLoading(true);
        let selectedTemplates = [];

        for (let i = 0; i < formData.templateTypes.length; i++) {
            const templateType = formData.templateTypes[i];

            for (let j = 0; j < templateType.values.length; j++) {
                const template = templateType.values[j];

                selectedTemplates.push({
                    id: template.id,
                    type: template.type,
                });
            }
        }

        updateOrganizationTemplatesAsync(selectedTemplates)
            .then(() => toast.success(t(lang, 'main.settings.tab.templates.toast.update_templates.success')))
            .catch(() => toast.error(t(lang, 'main.settings.tab.templates.toast.update_templates.success')))
            .finally(() => {
                loadOrganizationTemplates();
                setLoading(false);
            })
    }

    return (
        <Fragment>
            <FormProvider {...methods}>
                <Form onSubmit={methods.handleSubmit(onSubmit)}>
                    <Row className="g-3">
                        <Col xs={12} className="text-center">
                            <h4 className="text-black">{t(lang, 'main.settings.tab.templates.form.title')}</h4>
                        </Col>

                        {templateTypes.map((type, index) => (
                            <SelectTemplateComponent key={index} index={index} label={type.label} options={type.options}
                                                     arrayFieldName={templateTypesFieldName}/>
                        ))}

                        <Col xs={12} className="d-flex justify-content-end border-top pt-3">
                            <Button type="submit"
                                    className={"btn-davr px-3 py-0 bg-primary-davr"}
                                    variant={"primary"}
                            >
                                {loading &&
                                    <Spinner className='align-middle me-2' animation='border' role='switch' size='sm'/>}
                                {t(lang, 'main.settings.tab.templates.form.submit')}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </FormProvider>
        </Fragment>
    );
};

export default OrganizationTemplates;
