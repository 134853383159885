import axios from "axios";

class ContractorPaymentService {
    getContractorPayments(params) {
        return axios.get(`/contractor/payment/get?${params}`)
    }
    getContractorPaymentsCount(params) {
        return axios.get(`/contractor/payment/get/count?${params}`)
    }

    getContractorPayment(id) {
        return axios.get(`/contractor/payment/get/${id}`)
    }

    registerContractorPayment(payload) {
        return axios.post("/contractor/payment/register", payload);
    }

    editContractorPayment({payment_id, payload}) {
        return axios.post(`/contractor/payment/update/${payment_id}`, payload);
    }

    editContractorPaymentNote({payment_id, payload}) {
        return axios.post(`/contractor/payment/change-note/${payment_id}`, payload);
    }

    editContractorPaymentContractor({payment_id, payload}){
        return axios.post(`/contractor/payment/change-contractor/${payment_id}`, payload);
    }

    editContractorPaymentDate({payment_id, payload}) {
        return axios.post(`/contractor/payment/change-date/${payment_id}`, payload);
    }

    deleteContractorPayment({id}) {
        return axios.post(`/contractor/payment/delete/${id}`)
    }

    getContractorPayouts(params) {
        return axios.get(`/contractor/payout/get?${params}`)
    }
    getContractorPayoutsCount(params) {
        return axios.get(`/contractor/payout/get/count?${params}`)
    }

    getContractorPayout(id) {
        return axios.get(`/contractor/payout/get/${id}`)
    }

    registerContractorPayout(payload) {
        return axios.post("/contractor/payout/register", payload);
    }

    deleteContractorPayout({id}) {
        return axios.post(`/contractor/payout/delete/${id}`)
    }

    editContractorPayout({payout_id, payload}) {
        return axios.post(`/contractor/payout/update/${payout_id}`, payload);
    }

    editContractorPayoutNote({payout_id, payload}){
        return axios.post(`/contractor/payout/change-note/${payout_id}`, payload);
    }

    editContractorPayoutContractor({payout_id, payload}){
        return axios.post(`/contractor/payout/change-contractor/${payout_id}`, payload);
    }

    editContractorPayoutDate({payout_id, payload}){
        return axios.post(`/contractor/payout/change-date/${payout_id}`, payload);
    }
}

export default new ContractorPaymentService();