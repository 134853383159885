import React from 'react';
import {Button, Modal} from "react-bootstrap";
import IconAlertWarning from "../assets/icon/IconAlertWarning";
import IconAlertError from "../assets/icon/IconAlertError";
import IconAlertPrimary from "../assets/icon/IconAlertPrimary";
import {useSelector} from "react-redux";
import {selectLang} from "../store/reducers/main/main";
import {Translate} from "../lang/lang";

const RedirectAlert = ({show, setShow}) => {

    const lang = useSelector(selectLang);
    const t = Translate;

    return (
        <Modal centered show={show} onHide={()=>setShow(false)}>
            <Modal.Header closeButton />
            <Modal.Body className={'text-center'}>
                <IconAlertPrimary width={100} height={100} />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => setShow(false)} variant={'phoenix-secondary'}>{t(lang, "items.common.close")}</Button>
                <Button variant={'phoenix-primary'}>{t(lang, "Переход")}</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RedirectAlert;
