import React, {Fragment, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {selectLang} from "../../store/reducers/main/main";
import {Translate} from "../../lang/lang";
import {scrollEvent} from "../../helpers/scrollEvent";
import CommonDropdownButton from "../../components/common/DropdownButton";
import Flex from "../../components/common/Flex";
import {Button, Card, Col, Dropdown, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDown, faChevronRight} from "@fortawesome/free-solid-svg-icons";
import IconFileDownload from "../../assets/icon/IconFileDownload";
import IconFileUpload from "../../assets/icon/IconFileUpload";
import RevealDropdown from "../../components/common/RevealDropdown";
import DashboardChart from "./DashboardChart";
import IconFileDocument from "../../assets/icon/IconFileDocument";
import IconFileEdit from "../../assets/icon/IconFileEdit";

const WarehouseDashboard = () => {

    const lang = useSelector(selectLang);
    const t = Translate;

    const [chartType, setChartType] = useState('week')

    useEffect(() => {
        window.addEventListener('scroll', () => scrollEvent('warehouse-dashboard'));
        return () => window.removeEventListener('scroll', () => scrollEvent('warehouse-dashboard'))
    }, []);

    return (
        <Fragment>
            <Flex id={'warehouse-dashboard'}
                  className={'align-items-center justify-content-between position-sticky z-index-1'}
                  style={{top: '4rem'}}>
                <h4>{t(lang, "items.common.warehouse")}</h4>
                <CommonDropdownButton/>
            </Flex>
            <Row className={'mt-3 gap-y-4'}>
                <Col xs={12} md={6}>
                    <Card className={'p-2 text-decoration-none'} as={Link} to={'items'}>
                        <Flex className={'gap-2'}>
                            <IconFileDownload/>
                            <span className={'second-text'}>{t(lang, "main.templates.type.items")}</span>
                        </Flex>
                        <Flex className={'justify-content-between mt-2'}>
                            <h2 className={'fw-medium'}>100</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className={'p-2 text-decoration-none'} as={Link} to={'warehouses'}>
                        <Flex className={'gap-2'}>
                            <IconFileUpload/>
                            <span className={'second-text'}>{t(lang, "warehouse.common.item.title.warehouses")}</span>
                        </Flex>
                        <Flex className={'justify-content-between mt-2'}>
                            <h2 className={'fw-medium'}>3</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className={'p-2 text-decoration-none'} as={Link} to={'contractor'}>
                        <Flex className={'gap-2'}>
                            <IconFileDocument/>
                            <span
                                className={'second-text'}>{t(lang, "warehouse.operation.item.common.executors")}</span>
                        </Flex>
                        <Flex className={'justify-content-between mt-2'}>
                            <h2 className={'fw-medium'}>8</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
                <Col xs={12} md={6}>
                    <Card className={'p-2 text-decoration-none'} as={Link} to={'contractor-payments'}>
                        <Flex className={'gap-2'}>
                            <IconFileEdit/>
                            <span
                                className={'second-text'}>{t(lang, "warehouse.operation.item.common.repayments")}</span>
                        </Flex>
                        <Flex className={'justify-content-between mt-2'}>
                            <h2 className={'fw-medium'}>5</h2>
                            <FontAwesomeIcon icon={faChevronRight} className={'me-2 second-text'}/>
                        </Flex>
                    </Card>
                </Col>
            </Row>
            <div className="d-grid mt-4 w-100 gap-4"
                 style={{gridTemplateColumns: "repeat(2, 1fr)"}}>
                <Card className={'p-2'}>
                    <Flex className={'gap-2'}>
                        <IconFileDocument/>
                        <span className={'second-text'}>{t(lang, "warehouse.operation.item.common.we_one")}</span>
                    </Flex>
                    <Flex className={'justify-content-between mt-2'}>
                        <h2 className={'fw-medium'}>-8 215 227 291,00</h2>
                    </Flex>
                </Card>
                <Card style={{gridRow: "span 2"}}>
                    <Card.Header className={'p-3'}>
                        <Flex justifyContent={"between"}>
                            <Flex className={'gap-2'} justifyContent={"center"} alignItems={"center"}>
                                <h4 className={'m-0'}>{t(lang, "main.template.type.sales_chart")}</h4>
                                <FontAwesomeIcon className={'text-primary-davr'} icon={faChevronRight}/>
                            </Flex>
                            <div>
                                <Button className="p-0 text-secondary" variant="phoenix-warning">
                                    <RevealDropdown icon={
                                        <div className={'d-flex gap-2 align-items-center'}>
                                        <span
                                            className="second-text d-none d-sm-inline-block fw-middle ms-1 fs-8 text-nowrap fs-md-9">
                                            {t(lang, `main.template.type.chart_type`, {type: t(lang, `main.template.type.${chartType}`)})}
                                        </span>
                                            <FontAwesomeIcon className={'text-primary-davr'} icon={faChevronDown}/>
                                        </div>
                                    }>
                                        <Dropdown.Item active={chartType === "week"} key={"week"}
                                                       onClick={() => setChartType("week")}>
                                            {t(lang, "main.template.type.week")}
                                        </Dropdown.Item>
                                        <Dropdown.Item active={chartType === "month"} key={"month"}
                                                       onClick={() => setChartType("month")}>
                                            {t(lang, "main.template.type.month")}
                                        </Dropdown.Item>
                                        <Dropdown.Item active={chartType === "year"} key={"year"}
                                                       onClick={() => setChartType("year")}>
                                            {t(lang, "main.template.type.year")}
                                        </Dropdown.Item>
                                    </RevealDropdown>
                                </Button>
                            </div>
                        </Flex>
                    </Card.Header>
                    <Card.Body style={{paddingLeft: 0}}>
                        <DashboardChart/>
                    </Card.Body>
                </Card>
                <Card className={'p-2'}>
                    <Flex className={'gap-2'}>
                        <IconFileEdit/>
                        <span className={'second-text'}>{t(lang, "warehouse.operation.item.common.we_hae_to")}</span>
                    </Flex>
                    <Flex className={'justify-content-between mt-2'}>
                        <h2 className={'fw-medium'}>41 957 521 398,00</h2>
                    </Flex>
                </Card>
            </div>
        </Fragment>
    );
};

export default WarehouseDashboard;
